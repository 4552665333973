<template>
  <div>
    <form-dialog
      visible
      :title="viewOnly ? 'View Purchase Order' : 'Review Purchase Order'"
      :showFormTitle="false"
      submitLabel="Place order"
      draftEnabled
      :showSubmit="!viewOnly"
      @close="$emit('close')"
      @submit="submitHandle('Sent')"
      @submit:draft="submitHandle('Draft')"
    >
      <dialog-subheading
        :viewOnly="viewOnly"
        :order="order"
        :text="`Review and place your order`"
        @addComment="addComment"
        @addOrderNumber="addOrderNumber"
      />
      <v-data-table
        :headers="headers"
        :items="reviewRows"
        color="secondary"
        class="my-4 fill-width"
        disable-pagination
        hide-default-footer
      >
        <template v-slot:[`item.quantity`]="props">
          {{ props.item.quantity }}
          <span v-if="props.item.totalSize" class="text--lighttext">
            = {{ props.item.totalSize }}
          </span>
        </template>
        <template v-slot:[`item.caseOrPackageCost`]="props">
          ${{ getCostPerPackageOrCase(props.item).toFixed(2) }}
          {{ props.item.currency }} per&nbsp;{{
            (props.item.quantityUnit === "cases" && "case") || "pkg"
          }}
        </template>
        <template v-slot:[`item.subtotal`]="props">
          ${{ props.item.subtotal }} {{ props.item.currency }}
        </template>
        <template v-slot:[`body.append`]>
          <tr>
            <td colspan="4">Total ({{ order.purchaseOrderItems.length }} items)</td>
            <td>${{ totalCost }} {{ reviewRows[0].currency }}</td>
          </tr>
        </template>
      </v-data-table>
    </form-dialog>
  </div>
</template>

<script>
import { mapGetters, mapState, mapActions } from "vuex"
import FormDialog from "@/AuthenticatedContent/shared/dialogs/FormDialog.vue"
import DialogSubheading from "./DialogSubheading.vue"
import topLevelMixins from "@/AuthenticatedContent/mixins.js"

export default {
  name: "review-order",
  components: {
    FormDialog,
    DialogSubheading,
  },
  mixins: [topLevelMixins],
  props: {
    viewOnly: {
      type: Boolean,
      required: false,
    },
    value: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      loadingData: false,
      headers: [
        { text: "SKU", value: "supplierSKU" },
        { text: "Name", value: "supplierItemName" },
        { text: "Quantity Ordering", value: "quantity" },
        { text: "Cost", value: "caseOrPackageCost" },
        { text: "Subtotal", value: "subtotal" },
      ],
      order: this.value,
    }
  },
  computed: {
    ...mapState(["supplierItems"]),
    ...mapGetters(["getUnitName"]),
    reviewRows() {
      return (
        this.order.purchaseOrderItems?.map(item => {
          const supplierItem = this.supplierItems.find(
            si => si.id === item.supplierItemId
          )
          const multiplier =
            item.quantity *
            (item.quantityUnit === "cases" ? supplierItem.caseSize : 1)
          return {
            ...item,
            item: item,
            supplierSKU: supplierItem ? supplierItem.supplierSKU : "",
            supplierItemName: supplierItem ? supplierItem.name : "",
            supplierUnit: supplierItem ? supplierItem.linkedItemID : "",
            unitName: supplierItem
              ? this.getUnitName(
                  supplierItem.packageMeasurement.preferredDisplayUnitId
                )
              : "",
            totalSize: supplierItem
              ? this.getPackageMeasurementWithUnit(supplierItem, multiplier)
              : 1,
            supplierItem: supplierItem,
            packageSize: supplierItem
              ? supplierItem.packageMeasurement.quantity
              : "",
            currency: supplierItem ? supplierItem.costMoney.currency : "",
            quantity:
              `${item.quantity} ${item.quantityUnit} ` +
              (item.quantityUnit === "cases"
                ? ` = ${item.quantity * supplierItem.caseSize} pkgs `
                : ""),
            subtotal: parseFloat(item.subtotal).toFixed(2),
          }
        }) || []
      )
    },
    totalCost() {
      const total = this.order.purchaseOrderItems.reduce((sum, sItem) => {
        return sum + parseFloat(sItem.subtotal)
      }, 0)
      return total.toFixed(2)
    },
  },
  watch: {
    value: {
      handler: function (newVal) {
        this.order = newVal
      },
      deep: true,
    },
    order: {
      handler: function (newVal) {
        this.$emit("input", newVal)
      },
      deep: true,
    },
  },
  methods: {
    ...mapActions(["updatePurchaseOrders"]),
    addOrderNumber(value) {
      this.order.orderNumber = value
    },
    addComment(comment) {
      this.order.comment = comment
    },
    getReviewItemSubtotal(item) {
      return parseFloat(this.getCostPerPackageOrCase(item)).toFixed(2)
    },
    async submitHandle(status) {
      this.order.status = status
      await this.updatePurchaseOrders(this.order)

      if (this.$route.params.tab != "history") {
        this.$router.push({ params: { tab: "history", id: null } })
      }

      this.$emit("close")
    },
  },
}
</script>
<style scoped lang="scss">
.v-data-table tbody tr:last-child {
  background-color: var(--v-primary-base) !important;
  color: var(--v-text-on-primary-bg-base) !important;
  font-weight: bold;
}
</style>
