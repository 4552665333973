import moment from "moment"

export default {
  methods: {
    formatDateTime(date) {
      return {
        date: moment(date, "YYYY-MM-DD HH:mm:ss").format("MMM DD, YYYY"),
        time: moment(date, "YYYY-MM-DD HH:mm:ss").format("h:mm a"),
      }
    },
  },
}
