<template>
  <div>
    <page-component
      pageTitle="Supplier Purchase Orders"
      :headers="headers"
      :items="purchaseOrders"
      :sortDesc="true"
      :loadingData="loadingData"
      no-data-text="No orders found"
      :buttons="[
        {
          text: '+ New Order',
          to: { name: 'Purchase Orders', params: { tab: 'AddEditForm' } },
        },
      ]"
    >
      <template v-slot:[`item.date`]="props">
        {{ moment(props.item.date).format(dateFormat) }}
      </template>
      <template v-slot:[`item.orderGuideId`]="props">
        {{ getOrderGuideName(props.item.orderGuideId) }}
      </template>
      <template v-slot:[`item.supplier`]="props">
        {{ getSupplierName(props.item.supplierId) }}
      </template>
      <template v-slot:[`item.purchaseOrderItems`]="props">
        {{ props.item.purchaseOrderItems.length }}
      </template>
      <template v-slot:[`item.totalCost`]="props">
        ${{ parseFloat(getTotalCost(props.item)).toFixed(2) }}
      </template>
      <template v-slot:[`item.receiveOrder`]="{ item }">
        <nxg-action-btn
          v-if="item.status === 'Sent'"
          type="receive"
          @click="receiveOrder(item)"
        />
      </template>
      <template v-slot:[`item.actions`]="{ item }">
        <nxg-action-btn
          v-if="item.status === 'Draft'"
          type="edit"
          @click="$router.push({ params: { tab: 'AddEditForm', id: item.id } })"
        />
        <nxg-action-btn
          v-else-if="item.status !== 'Received'"
          type="eye"
          @click="orderToView = item"
        />
        <nxg-action-btn type="delete" @click="deleteItem(item)" />
      </template>
    </page-component>
    <review
      v-if="orderToView !== null"
      v-model="orderToView"
      :supplierItems="supplierItems"
      viewOnly
      @close="orderToView = null"
    />
    <delete-dialog
      :show="showDeleteDialog"
      itemType="PURCHASE_ORDER"
      :itemToDelete="itemToDelete"
      @close="showDeleteDialog = !showDeleteDialog"
    />
  </div>
</template>

<script>
import { mapState, mapGetters } from "vuex"
import PageComponent from "@/AuthenticatedContent/shared/PageComponent.vue"
import Review from "./Review.vue"
import DeleteDialog from "@/AuthenticatedContent/shared/dialogs/DeleteDialog.vue"
export default {
  name: "purchase-orders",
  components: {
    PageComponent,
    Review,
    DeleteDialog,
  },
  data() {
    return {
      loadingData: false,
      orderToView: null,
      showDeleteDialog: false,
      itemToDelete: {},
      headers: [
        { text: "Order #", value: "orderNumber" },
        { text: "Date", value: "date" },
        { text: "Order Guide", value: "orderGuideId" },
        { text: "Supplier", value: "supplier" },
        { text: "Items Ordered", value: "purchaseOrderItems" },
        { text: "Total Cost", value: "totalCost" },
        { text: "Status", value: "status" },
        { text: "Receive", value: "receiveOrder" },
        {
          text: "Actions",
          value: "actions",
          sortable: false,
          align: "center",
        },
      ],
    }
  },
  computed: {
    ...mapGetters(["getSupplierName", "getOrderGuideName"]),
    ...mapState(["orderGuides", "purchaseOrders", "supplierItems"]),
    dateFormat() {
      return "MMM D, YYYY h:mm A"
    },
  },
  methods: {
    getTotalCost(order) {
      return order.purchaseOrderItems.reduce((acc, item) => acc + item.subtotal, 0)
    },
    deleteItem(item) {
      this.itemToDelete = item
      this.showDeleteDialog = true
    },
    receiveOrder(order) {
      this.$router.push({
        name: "Receiving",
        params: { tab: "AddEditForm" },
        query: { purchaseOrderId: order.id },
      })
    },
  },
}
</script>
