<template>
  <div>
    <form-dialog
      v-if="loaded"
      title="Place a New Purchase Order"
      :showFormTitle="false"
      submitLabel="Continue"
      :visible="!initialFormSubmitted"
      small
      @close="$route.params.tab = 'history'"
      @submit="initialFormSubmitted = true"
    >
      <p class="mb-6">Select an order guide to continue:</p>
      <v-select
        v-model="order.orderGuideId"
        label="Order Guide"
        :items="orderGuides"
        item-value="id"
        item-text="name"
        outlined
        dense
        hide-details="auto"
      />
    </form-dialog>
    <supplier-items-form
      v-if="initialFormSubmitted"
      v-model="items"
      page-title="Place a New Purchase Order"
      :loaded="loaded"
      @change="handleItemChange"
    >
      <template v-slot:filters[0]>
        <custom-date-picker
          v-model="order.date"
          label="Purchase Order Date/Time"
          show-time
          init
          dense
        />
      </template>
      <template v-slot:filters[1]>
        <v-select
          v-model="order.orderGuideId"
          label="Order Guide"
          :items="orderGuides"
          item-value="id"
          item-text="name"
          outlined
          dense
          hide-details="auto"
          @change="
            confirm('This will clear your current order. Are you sure?') &&
              prepareItems()
          "
        />
      </template>
    </supplier-items-form>
    <v-footer fixed elevation="3" class="pa-4 fixed-btn-container">
      <v-btn
        text
        color="primary"
        class="fixed-btn ml-auto"
        @click="$router.push({ params: { tab: null, id: null } })"
      >
        Cancel
      </v-btn>
      <v-btn
        :disabled="order.purchaseOrderItems.length === 0"
        color="primary"
        class="fixed-btn ml-4"
        @click="openReviewDialog"
      >
        Review
      </v-btn>
    </v-footer>
    <review
      v-if="reviewDialogOpen"
      v-model="order"
      @close="reviewDialogOpen = false"
    />
  </div>
</template>

<script>
import { mapState } from "vuex"
import moment from "moment"
import FormDialog from "@/AuthenticatedContent/shared/dialogs/FormDialog.vue"
import SupplierItemsForm from "@/AuthenticatedContent/shared/forms/SupplierItemsForm.vue"
import Review from "./Review.vue"
import CustomDatePicker from "@/AuthenticatedContent/shared/forms/datetime/CustomDatePicker.vue"
import topLevelMixins from "@/AuthenticatedContent/mixins.js"

export default {
  name: "add-edit-form",
  components: {
    FormDialog,
    SupplierItemsForm,
    Review,
    CustomDatePicker,
  },
  mixins: [topLevelMixins],
  data() {
    return {
      loaded: false,
      initialFormSubmitted: false,
      reviewDialogOpen: false,
      items: [],
      order: {
        date: moment().format("YYYY-MM-DD HH:mm:ss"),
        orderGuideId: null,
        supplierId: null,
        purchaseOrderItems: [],
      },
    }
  },
  computed: {
    ...mapState(["orderGuides", "purchaseOrders", "supplierItems"]),
  },
  async mounted() {
    if (this.$route.params.id) {
      this.order = this.purchaseOrders.find(
        order => order.id === this.$route.params.id
      )
    } else {
      this.$set(
        this.order,
        "orderGuideId",
        this.orderGuides?.length === 1 ? this.orderGuides[0].id : null
      )
    }
    if (this.order?.orderGuideId) {
      this.initialFormSubmitted = true
    }
    this.prepareItems()
    this.loaded = true
  },
  methods: {
    prepareItems() {
      this.items = this.supplierItems
        .filter(
          supplierItem =>
            supplierItem.orderGuideId === this.order.orderGuideId &&
            supplierItem.linkedItemID
        )
        .map(supplierItem => {
          supplierItem.quantity = 0
          supplierItem.quantityUnit = "pkgs"
          if (this.order?.purchaseOrderItems?.length) {
            const existingItem = this.order.purchaseOrderItems.find(
              purchaseOrderItem =>
                purchaseOrderItem.supplierItemId === supplierItem.id
            )
            if (existingItem) {
              supplierItem.quantity = existingItem.quantity
              supplierItem.quantityUnit = existingItem.quantityUnit
            }
          }
          return supplierItem
        })
      this.$set(this.order, "purchaseOrderItems", [])
    },
    openReviewDialog() {
      this.$set(
        this.order,
        "supplierId",
        this.orderGuides.find(
          orderGuide => orderGuide.id === this.order.orderGuideId
        ).supplierId
      )
      this.reviewDialogOpen = true
    },
    handleItemChange(item) {
      let itemInOrder = this.order.purchaseOrderItems.find(
        sItem => sItem.supplierItemId === item.id
      )
      if (!itemInOrder) {
        this.order.purchaseOrderItems.push({
          supplierItemId: item.id,
          baseUnitCostMoney: {
            amount: item.costMoney.amount,
            currency: item.costMoney.currency,
          },
        })
        itemInOrder =
          this.order.purchaseOrderItems[this.order.purchaseOrderItems.length - 1]
      }

      itemInOrder.quantity = Number(item.quantity)
      itemInOrder.quantityUnit = item.quantityUnit
      itemInOrder.costPerUnit = this.getCostPerUnit(item)
      itemInOrder.caseSize = Number(item.caseSize)
      itemInOrder.subtotal = Number(item.subtotal)

      // remove items with 0 quantity
      this.$set(
        this.order,
        "purchaseOrderItems",
        this.order.purchaseOrderItems.filter(item => item.quantity > 0)
      )
    },
  },
}
</script>
