<template>
  <div>
    <v-row>
      <v-col cols="6">
        <v-row>
          <v-col cols="6">
            <v-list flat dense color="transparent" class="pa-0">
              <v-list-item class="pa-0">
                <v-list-item-content>
                  <v-list-item-title>Date:</v-list-item-title>
                  <v-list-item-subtitle>
                    {{ formatDateTime(order.date).date }}
                    @
                    {{ formatDateTime(order.date).time }}
                  </v-list-item-subtitle>
                </v-list-item-content>
              </v-list-item>
              <v-list-item class="pa-0">
                <v-list-item-content>
                  <v-list-item-title>Submitted by:</v-list-item-title>
                  <v-list-item-subtitle>
                    {{ getUserDisplayName() }}
                  </v-list-item-subtitle>
                </v-list-item-content>
              </v-list-item>
            </v-list>
          </v-col>
          <v-col cols="6">
            <v-list flat dense color="transparent" class="pa-0">
              <v-list-item class="pa-0">
                <v-list-item-content>
                  <v-list-item-title>Supplier:</v-list-item-title>
                  <v-list-item-subtitle>
                    {{ getSupplierName(order.supplierId) }}
                  </v-list-item-subtitle>
                </v-list-item-content>
              </v-list-item>
              <v-list-item class="pa-0">
                <v-list-item-content>
                  <v-list-item-title>Order guide:</v-list-item-title>
                  <v-list-item-subtitle>
                    {{ getOrderGuideName(order.orderGuideId) }}
                  </v-list-item-subtitle>
                </v-list-item-content>
              </v-list-item>
            </v-list>
          </v-col>
        </v-row>
        <v-row class="mt-0">
          <v-col cols="6" class="pt-3">
            <v-text-field
              v-if="!viewOnly"
              v-model="order.orderNumber"
              name="orderNumber"
              label="Order number"
              class="mt-2"
              outlined
              @change="$emit('addOrderNumber', order.orderNumber)"
            />
            <v-list v-else flat dense color="transparent" class="pa-0">
              <v-list-item class="pa-0">
                <v-list-item-content class="pt-0">
                  <v-list-item-title>Order number:</v-list-item-title>
                  <div class="text--lighttext">
                    {{ order.orderNumber }}
                  </div>
                </v-list-item-content>
              </v-list-item>
            </v-list>
          </v-col>
        </v-row>
      </v-col>
      <v-col cols="6">
        <v-textarea
          v-if="!viewOnly"
          v-model="order.comment"
          label="Comments"
          class="mt-2"
          outlined
          rows="5"
          @change="$emit('addComment', order.comment)"
        />
        <v-list v-else flat dense color="transparent" class="pa-0">
          <v-list-item class="pa-0">
            <v-list-item-content>
              <v-list-item-title>Comments:</v-list-item-title>
              <div class="text--lighttext">{{ order.comment }}</div>
            </v-list-item-content>
          </v-list-item>
        </v-list>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import { mapGetters } from "vuex"
import formatDateTime from "@/mixins/formatDateTime"
import getUserDisplayName from "@/mixins/getUserDisplayName"

export default {
  name: "details-subheading",
  mixins: [formatDateTime, getUserDisplayName],
  props: {
    order: {
      type: Object,
      required: true,
    },
    viewOnly: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  data() {
    return {
      comment: "",
      orderNumber: "",
    }
  },
  computed: {
    ...mapGetters(["getSupplierName", "getOrderGuideName"]),
  },
}
</script>
