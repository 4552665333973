<template>
  <v-sheet class="mx-n4 mt-n4">
    <v-tabs v-model="active" color="text" background-color="primary-darken4">
      <v-tab
        v-for="tab in tabs"
        :key="'tab-' + tab.name"
        :to="{ path: '/purchase/' + tab.name }"
      >
        {{ tab.label }}
      </v-tab>
    </v-tabs>
    <v-tabs-items v-model="$route.params.tab" touchless>
      <v-tab-item
        v-for="tab in tabs"
        :key="'tab-item-' + tab.name"
        :value="tab.name"
        class="ma-4"
      >
        <component :is="tab.component" v-if="$route.params.tab === tab.name" />
      </v-tab-item>
    </v-tabs-items>
  </v-sheet>
</template>

<script>
import AddEditForm from "./AddEditForm.vue"
import Orders from "./History.vue"
import { mapActions } from "vuex"

export default {
  name: "purchase-orders",
  components: {
    AddEditForm,
    Orders,
  },
  data() {
    return {
      active: "AddEditForm",
      tabs: [
        {
          name: "AddEditForm",
          label: "Place New Order",
          component: "add-edit-form",
        },
        { name: "history", label: "Purchase Orders", component: "orders" },
      ],
    }
  },
  mounted() {
    this.fetchInventoryData()
  },
  methods: {
    ...mapActions(["fetchInventoryData"]),
  },
}
</script>
