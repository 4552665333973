import { getAuth } from "firebase/auth"

export default {
  methods: {
    getUserDisplayName() {
      const user = getAuth().currentUser

      if (!user) return "Anonymous"
      if (user?.displayName) return user.displayName
      else if (this.users) {
        const userDoc = Object.values(this.users)?.find(u => u.userId === user.uid)
        if (userDoc?.name) return userDoc.name
      }
      return user.email
    },
  },
}
